.SectionTitle {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: var(--black);
  display: inline-block;
  font-family: var(--titleFont);
  display: flex;
  align-items: center;
  margin-bottom: 32px; }
