.DropdownMenu {
  position: relative;
  .Icon {
    margin-left: 4px; } }
.DropdownMenu-Container {
  min-width: 200px;
  display: none;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  background-color: #fff;
  position: absolute;
  right: 0;
  box-shadow: var(--boxShadow);
  padding: 16px;
  top: 36px; }
.Spoiler_status_expanded {
  .DropdownMenu-Title {
    .Icon {
      transform: rotate(180deg); } }
  .DropdownMenu-Container {
    display: grid; } }
