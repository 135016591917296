.Partners {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  .PartnerTile {
    width: 100%;
    height: 100px;
    padding: 8px;
    background-color: #fff;
    border: 2px var(--lightGray) solid;
    display: block;
    border-radius: 12px;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; } } }
