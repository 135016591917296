.PhoneLinks {
  display: flex;
  align-items: center;
  .Icon {
    color: var(--accent); }

  a {
    color: var(--accent);
    margin-left: 12px; }
  .PhoneNumber {
    color: var(--black);
    font-size: 16px;
    font-weight: 700; } }
