.About {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-repeat: no-repeat;
  background-position: top 300px left 0px; }
.About-Container {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 32px; }
.AboutVideo {
  width: 100%;
  height: 300px; }
.AboutImg {

  object-fit: cover;
  object-position: center center; }
.About-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8);
  .Button {
    justify-self: start; }
  .BenefitsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-bottom: 16px;
    .InfoLine {
      flex-wrap: nowrap; } } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; }
  .AboutVideo {
    height: 250px; } }
@media screen and ( max-width: 992px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .AboutVideo {
    height: 300px; } }
