.RequestConsultation {
  background: linear-gradient(134deg, #FBFCFF 0%, #E2EBFB 100%);
  display: flex;
  justify-content: center;
  padding: 64px 0px;

  .RequestConsultation-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    justify-items: center; } }

.RequestConsultation-Form {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 24px; }

@media screen and ( max-width: 768px ) {
  .RequestConsultation {
    text-align: center; }
  .RequestConsultation-Form {
    grid-template-columns: 1fr; } }
