.Site-Nav {
  width: 100%;
  height: 64px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.4s ease; }
.Nav-Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }
.Site-Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Site-Nav-Menu {
  display: grid;
  grid-template-columns: repeat(8, max-content);
  grid-gap: 16px;
  align-items: center;
  justify-self: end; }

.Site-NavLink {
  color: var(--darkGray);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  &:hover {
    color: var(--accent); } }
.MobileSocial {
  display: none; }

@media screen and ( max-width:  1200px) {
  .Site-Hamburger-Container {
    flex-shrink: 0;
    margin-right: 4px; }
  .Nav-Container {
    justify-content: flex-start;
    .SocialLinks {
      margin-right: 24px; } }
  .MobileSocial {
    margin-left: auto;
    display: block; }
  .Site-Nav-Menu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: #fff;
    padding: 16px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    height: auto;
    grid-template-rows: repeat(5, minmax(0, max-content));
    grid-gap: 8px; }
  .DropdownMenu {
    .DropdownMenu-Title {
      width: 100%;
      display: block; }
    .DropdownMenu-Container {
      background-color: var(--background);
      position: relative;
      padding: 12px;
      top: 0;
      box-shadow: none; } }

  .Site-NavLink {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px var(--lightGray) solid;
    font-size: 16px;
    padding: 8px 0px;
    &:hover {
      background: none; }
    &:last-child {
      border-bottom: 0; } }
  .Site-NavLink_theme_active {
    color: var(--lightGray);
    background: none; } }
@media screen and ( max-width: 992px ) {
  .MobileSocial {
    .PhoneLinks {
      margin-right: 24px; }
    .SocialLinks {
      display: none; } } }
@media screen and ( max-width: 576px ) {
  .Nav-Container {
    .Logo {
 } }      // margin-left: 21px
  .MobileSocial {
    .PhoneLinks {
      a {
        display: none; }
      .PhoneIcon {
        margin-left: -14px; } } } }

// @media screen and ( max-width: 768px )

//   .MobileContacts
//     display: grid
//     grid-template-columns: repeat(2, max-content)
//     grid-gap: 24px
//   .Site-Nav
//     padding: 0px 24px
//     grid-template-columns: max-content 1fr max-content
//     grid-gap: 24px
//     // top: 32px

// @media screen and ( max-width:  576px)
//   .MobileContacts
//     display: grid
//     grid-template-columns: max-content
//     .PhoneChooser
//       display: none
//   .Site-Nav
//     padding: 0px 16px
//   .Site-Nav-Menu
//     padding: 16px
//     .PhoneChooser
//       display: block
//       color: #fff
//       .Icon
//         color: var(--black)
