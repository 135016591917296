.FeedsCarousel {
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.FeedsCarousel-Feeds {
  display: flex; }
.FeedContainer {
  width: 1200px;
  // margin-right: 24px
 }  // flex-shrink: 0

.RatingButtons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
  margin-bottom: 32px;
  .Google, .Facebook {
    background-color: #0577FC;
    color: #fff; } }

.RatingButton {
  padding: 0px 12px;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 4px;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  .Icon {
    font-size: 12px; } }
.FeedBack-Block {
  width: 100%;
  background-color: var(--accent);
  padding: 16px;
  color: #fff;
  border-radius: 12px;
  .Title {
    font-family: var(--titleFont);
    font-weight: 700;
    font-size: 18px; }
  .Description {
    margin: 16px 0px; }
  .FeedBack-Buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px; }
  .Button {
    width: 100%; }
  .PrimaryWhite {
    background-color: #fff;
    color: var(--accent); }
  .SecondaryWhite {
    border: 2px #fff solid; } }

@media screen and ( max-width: 1200px ) {
  .FeedContainer {
    width: 992px; } }
@media screen and ( max-width: 992px ) {
  .FeedContainer {
    width: 768px; } }
@media screen and ( max-width: 768px ) {
  .FeedContainer {
    width: 576px; } }
@media screen and ( max-width: 576px ) {
  .FeedContainer {
    width: 360px; } }

// @media screen and ( max-width: 576px )
//   .FeedsCarousel-Feeds
//     grid-template-columns: 1fr
