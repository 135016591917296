.MainServices {
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-repeat: no-repeat;
  background-position: bottom 0px right 0px;

  .Services-Cloud {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 16px; }
  .CloudTile {
    padding: 16px 12px;
    font-weight: 600;
    border: 2px var(--lightGray) solid;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      color: var(--accent);
      border: 2px rgba(234, 240, 252, 1) solid; } }
  .CloudTile_theme_active {
    color: var(--accent);
    border: 2px rgba(234, 240, 252, 1) solid;
    background-color: rgba(234, 240, 252, 1); }
  .ActiveService-Container {
    margin-top: 24px;
    .Title {
      font-size: 24px;
      color: var(--black);
      font-family: var(--titleFont);
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 24px; } }
  .Services-Button {
    display: flex;
    justify-content: center;
    margin-top: 24px; } }
