.Edu {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  .Hero-Categories {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 16px; }
  .CategoryTile {
    background-color: #EBF2FB;
    border-radius: 8px;
    img {
      width: 100%;
      height: 320px;
      border-radius: 8px;
      object-fit: cover;
      object-position: center center; }
    .Title {
      font-size: 21px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--black); }
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px;
      grid-gap: 16px; } } }
.BreadCrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .BreadCrumbs_Link_active {
    color: var(--accent); }

  a {
    color: var(--darkGray);
    &:hover {
      color: var(--accent);
      text-decoration: underline !important; } }
  span {
    &:not(last-child) {
      margin-right: 8px; } } }

@media screen and ( max-width: 768px ) {
  .Edu {
    .Hero-Categories {
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }
