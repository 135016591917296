.News {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  .SubTitle {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--black); } }

.News-Container {
  display: grid;
  grid-template-columns: 1fr 250px 250px;
  grid-gap: 24px;
  margin-top: 24px;
  .ModuleSpinner-Container {
    grid-column: 1/-2; }
  .TopNews {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    align-content: start; }
  .BigTopType {
    grid-column: 1/-1; }
  .TopType {
    .NewsTile-Image {
      height: 300px; } }
  .OtherNews {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-content: start;
    .Button {
      width: 100%; }
    .DefaultType {
      .Title {
        font-size: 14px; }
      .NewsTile-Content {
        margin-top: 0; }
      .NewsTile-Image {
        display: none; }
      &:not(:last-child) {
        border-bottom: 1px var(--lightGray) solid;
        padding-bottom: 12px; } } }
  .AskBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-content: start;
    .AskImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: top center; }
    .Button {
      width: 100%; } } }
.NewsTile {
  width: 100%;
  cursor: pointer;

  .NewsTile-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 16px;
    color: var(--black);
    .Date {
      color: var(--darkGray); } }
  .NewsTile-Image {
    width: 100%;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; } } }
@media screen and ( max-width: 1024px ) {
  .News-Container {
    grid-template-columns: 1fr 250px;
    .TopNews {
      order: 0; }
    .AskBlock {
      order: 1; }
    .OtherNews {
      order: 2; } } }

@media screen and ( max-width: 768px ) {
  .News-Container {
    grid-template-columns: 1fr;
    .TopNews {
      grid-template-columns: 1fr;
      order: 0; }
    .OtherNews {
      order: 1;
      h4 {
        display: none; }
      h3 {
        font-size: 18px !important; } }
    .AskBlock {
      order: 2;
      .Button {
        width: auto; }
      .Buttons {
        display: flex;
        justify-content: center; } } } }
