
.EduPage {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  .Content {
    display: grid;
    grid-template-columns: 1fr; }
  .Avatar {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center center;
    margin-bottom: 24px; }
  .Content {
    margin-top: 24px; } }
