.FeedTile {
  width: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  background-color: #fff;
  border-radius: 12px;
  border: 2px var(--lightGray) solid;
  cursor: pointer;
  align-content: start;
  &:hover {
    box-shadow: var(--boxShadow); }
  .Feed-Author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .UserName {
      font-size: 16px;
      color: var(--black);
      font-weight: 600; }
    .Date {
      font-size: 12px;
      font-weight: 400; } }
  .Feed-Video {
    height: 300px; }
  .Feed-Text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400; }
  .Feed-Controlls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ControllButton {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600; }
    .Icon {
      font-size: 12px;
      color: var(--accent); } }
  .Feed-Service {
    border-top: 1px var(--lightGray) solid;
    padding-top: 12px;
    .Tag {
      font-size: 12px;
      font-weight: 600;
      color: var(--accent);
      background-color: rgba(234, 240, 252, 1);
      padding: 2px 8px;
      display: inline-block; } } }
