.Top {
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  justify-content: center; }

.Top-Container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  .SocialLinks {
    margin-left: 24px; } }

.Top-Contact-Buttons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 32px; }

.Top-Service-Buttons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 32px; }
@media screen and ( max-width:  1200px) {
  .Top {
    display: none; } }

// @media screen and ( max-width: 576px )
//   .Top-Contact-Buttons
//     width: 100%
//     display: flex
//     justify-content: space-between
//     .Top-ContactsEmail
//       display: none
//   .Top-Service-Buttons
//     display: none
