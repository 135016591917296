.Site-Hero {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  background-color: var(--background);
  align-items: center;
  .Hero-Carousel {
    height: 350px;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: top left; } } }
@media screen and ( max-width: 576px ) {
  .Site-Hero {
    height: 300px;
    .Hero-Carousel {
      height: 300px;
      img {
        height: 300px; } } } }
