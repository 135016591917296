.NewsArchive {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: #f1f7f9; }
.NewsArchive-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  .NewsTile {
    padding-bottom: 16px;
    .NewsTile-Image {
      display: none; }
    &:not(:last-child) {
      border-bottom: 1px var(--lightGray) solid; } } }
